import { MvExpert } from '@/lib/strapi-types/MvExpert';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindExpertsProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindExpertsSlider({
  filters = {},
  populate = []
}: UseFindExpertsProps = {}) {
  const allPopulate = [...populate];

  const parsedQuery = stringify(
    {
      filters,
      pagination: { start: 0, limit: 40 },
      populate: allPopulate
    },
    { arrayFormat: 'brackets' }
  );

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvExpert['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.EXPERTS_SLIDER)}?${parsedQuery}`
  );

  return { expertsSlider: data.value?.data };
}
